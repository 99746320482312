// config.js

const bizIcon = 'https://jeyfracleaning.com/wp-content/uploads/2023/11/Diseno-sin-titulo-2024-12-04T160612.048-e1733357410279.png';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://jeyfracleaning.com/wp-content/uploads/2024/12/livingroom-setting-1536x1024.jpg";
const imageTwo = "https://jeyfracleaning.com/wp-content/uploads/2024/12/modern-kitchen-in-luxury-highrise-apartment-1024x743.jpg";
const imageThree = "https://jeyfracleaning.com/wp-content/uploads/2024/12/luxury-bathroom-interior-1024x682.jpg";
const imageFour = "https://jeyfracleaning.com/wp-content/uploads/2024/12/moving-boxes-with-packed-stuff-and-chair-for-moving-1024x682.jpg";
const imageFive = "https://jeyfracleaning.com/wp-content/uploads/2024/12/blank-advertising-banner-in-airport-setting-1024x683.jpg";

const redirectLink = "https://jeyfracleaning.com/";

export const siteConfig = {
  title: "Jeyfra Cleaning Services",
  metaDescription: "Jeyfra Cleaning Services",
  theme: {
    primaryColor: "red-600",
    secondaryColor: "gray-900",
    accentColor: "text-red-600"
  },
  logo: {
    src: bizIcon,
    alt: "Jeyfra Cleaning Services Logo",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Jeyfra Cleaning Services",
    headline: "Professional Cleaning Services for Impeccable Spaces",
    address: "New York, NY, USA",
    description: `
        Jeyfra Cleaning Services is your trusted partner for spotless spaces in New York City. Specializing in residential, commercial, and office cleaning, our professional team ensures every space is refreshed and revitalized. Whether you need recurring services or a one-time deep clean, we deliver exceptional results tailored to your needs. Transform your environment with Jeyfra Cleaning Services—where cleanliness meets perfection.
    `,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Professional Cleaning Services for Impeccable Spaces",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Attention to Detail: We clean every corner with precision, ensuring your space looks and feels flawless.",
    },
    {
        description: 
          "Reliable and On-Time: Trust us to always deliver exceptional cleaning, right when you need it.",
    },
    {
        description: 
          "Personalized Cleaning: We adapt to your needs, providing tailored services that exceed expectations.",
    },
  ],
  services: {
    sectionTitle: "Our Cleaning Services",
    description: 
      "Jeyfra Cleaning Services offers top-notch cleaning solutions tailored to fit your lifestyle or business needs. From meticulous Residential Cleaning to transformative Deep Cleaning and stress-free Move-Out/Move-In services, we ensure every space is pristine and welcoming. Serving New York and surrounding areas, our expert team delivers reliable and professional cleaning services designed to exceed your expectations.",
    callouts: [
      {
        name: 'Residential Cleaning',
        description: 
          "Let Jeyfra Cleaning Services bring a fresh start to your home. Our Residential Cleaning service ensures every corner sparkles, leaving your space spotless, comfortable, and ready to enjoy. Tailored plans meet your unique lifestyle needs, making your home a haven of relaxation and freshness.",
        imageSrc: imageTwo,
        imageAlt: 'Professional cleaners working on a residential home.',
      },
      {
        name: 'Deep Cleaning',
        description: 
          "Our Deep Cleaning service goes beyond the surface to restore your home’s brilliance. Perfect for seasonal resets or special occasions, we tackle hidden dirt and grime, leaving every corner refreshed and rejuvenated. Trust us to transform your home into a sanctuary of perfection.",
        imageSrc: imageThree,
        imageAlt: 'Team performing deep cleaning service in a living room.',
      },
      {
        name: 'Move Out / Move In Cleaning',
        description: 
          "Make your move stress-free with Jeyfra Cleaning Services. Our Move-In and Move-Out Cleaning ensures your space is spotless, preparing it for its next chapter. From scrubbing every corner to polishing surfaces, we make moving easy and hassle-free.",
        imageSrc: imageFour,
        imageAlt: 'Cleaning team preparing a property for move-in or move-out.',
      },
    ],
    otherServices: [
      "Airbnb Cleaning",
      "Post Construction Cleaning",
      "Commercial Cleaning",
    ]
  },
  about: {
    sectionTitle: "About Us",
    description:   
      "Jeyfra Cleaning Services transforms spaces into pristine havens with unmatched professionalism and attention to detail. From residential homes to commercial offices, we provide tailored cleaning solutions designed to exceed expectations. Our dedicated team ensures every surface sparkles and every corner is immaculate, creating a healthier, fresher, and more vibrant environment for our clients. Trust Jeyfra Cleaning Services to deliver exceptional results, so you can focus on what matters most.",
    image: {
      src: imageFive,
      alt: "Jeyfra Cleaning Services team delivering professional cleaning services in New York, NY.",
      width: 1200,
      height: 800,
    },
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "New York, NY 10956",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12089.53126833041!2d-74.0018424!3d40.712776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5b4e6db3b%3A0xabcdef123456789!2sNew%20York%2C%20NY%2010956%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=New+York,+NY+10956',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=New+York,+NY+10956&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/New+York,+NY+10956/@40.712776,-74.006000,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/New+York,+NY+10956/@40.712776,-74.006000,15z',
      },
    ],  
  },
  contact: {
    sectionTitle: "Get in Touch with Jeyfra Cleaning Services",
    description: 
      "Transform your spaces with Jeyfra Cleaning Services. Whether you need residential cleaning, deep cleaning, or move-in/move-out cleaning, our dedicated team is here to help. We take pride in delivering exceptional, customized cleaning solutions tailored to your needs. Contact us today to experience impeccable service and spotless results.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (845) 200-6262",
    ],
    logo: bizIcon,
    emailRecipient: "frmadin.duarte@icloud.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


